import { filterNull } from '../../common/utils/filter-null';
import { type TeamHealthMonitor } from '../../types';
import { type ClientConfig } from '../base-client';
import { DEFAULT_CONFIG } from '../constants';
import { BaseGraphQlClient } from '../graphql-client';
import { logException } from '../sentry/main';

const buildTeamHealthQuery = (teamId: string, cloudId: string) => ({
	query: `query TeamHealthMonitors($teamId: String!, $cloudId: String) {
    TeamHealthMonitors: teamHealthMonitorsByTeamId(teamId: $teamId, sort: CREATION_DATE_DESC, first: 5) {
      edges {
        node {
          creationDate
          isComplete(cloudId: $cloudId)
          uuid
          teamHealthMonitorResults(cloudId: $cloudId) {
            rating
          }
        }
      }
    }
  }`,
	variables: {
		teamId,
		cloudId,
	},
});

type QueryResult = {
	TeamHealthMonitors: {
		edges: ReadonlyArray<{
			node: {
				creationDate: string | null;
				isComplete: boolean | null;
				uuid: string | null;
				teamHealthMonitorResults: ReadonlyArray<{
					rating: 'GREEN' | 'YELLOW' | 'RED' | null;
				}> | null;
			} | null;
		}> | null;
	};
};

const delay = (millis: number) =>
	new Promise((resolve) => {
		setTimeout(resolve, millis);
	});

export class AtlasClient extends BaseGraphQlClient {
	constructor(baseUrl: string, config: ClientConfig) {
		super(`${baseUrl}/watermelon/graphql`, config);
	}

	setBaseUrl(baseUrl: string) {
		this.setServiceUrl(`${baseUrl}/watermelon/graphql`);
	}

	private canRetryError(pendingRetries: number, error: unknown): boolean {
		return (
			pendingRetries > 0 &&
			!!error &&
			typeof error === 'object' &&
			'category' in error &&
			(error as Record<string, string>).category === 'NotFound'
		);
	}

	/**
	 * Gets team health monitor data
	 * @param {string} teamId
	 * @returns {Promise}
	 * @private
	 */
	queryTeamHealthMonitors(teamId: string): Promise<TeamHealthMonitor[]> {
		const teamHealthQuery = buildTeamHealthQuery(teamId, this.getContext().cloudId);

		const performRequest = async (
			pendingRetries = 3,
			backoffMaxDelay = 2000,
		): Promise<TeamHealthMonitor[]> => {
			try {
				const data = (await this.makeGraphQLRequest(teamHealthQuery, {
					operationName: 'TeamHealthMonitors',
				})) as QueryResult;

				return (
					data.TeamHealthMonitors.edges
						?.map((edge) => {
							const node = edge?.node;
							if (!node || !node.uuid || !node.creationDate) {
								return null;
							}
							return {
								date: node.creationDate,
								uuid: node.uuid,
								greenCount:
									node.teamHealthMonitorResults?.filter((result) => result.rating === 'GREEN')
										.length ?? 0,
								yellowCount:
									node.teamHealthMonitorResults?.filter((result) => result.rating === 'YELLOW')
										.length ?? 0,
								redCount:
									node.teamHealthMonitorResults?.filter((result) => result.rating === 'RED')
										.length ?? 0,
							};
						})
						.filter(filterNull) ?? []
				);
			} catch (error) {
				if (this.canRetryError(pendingRetries, error)) {
					await delay(backoffMaxDelay / pendingRetries);
					return performRequest(pendingRetries - 1);
				}

				throw error;
			}
		};

		return performRequest();
	}
}

export const atlasClient = new AtlasClient(DEFAULT_CONFIG.stargateRoot, {
	logException,
});
