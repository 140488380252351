// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize } from '@atlaskit/theme/constants';

import { type FlexProps } from './types';

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Flex = styled.div<FlexProps>`
	display: flex;

	flex: ${({ flex }) => flex};
	flex-basis: ${({ flexBasis }) => flexBasis};
	flex-direction: ${({ flexDirection }) => flexDirection};
	flex-flow: ${({ flexFlow }) => flexFlow};
	flex-grow: ${({ flexGrow }) => flexGrow};
	flex-shrink: ${({ flexShrink }) => flexShrink};
	flex-wrap: ${({ flexWrap }) => flexWrap};
	align-content: ${({ alignContent }) => alignContent};
	align-items: ${({ alignItems }) => alignItems};
	align-self: ${({ alignSelf }) => alignSelf};
	justify-content: ${({ justifyContent }) => justifyContent};
	order: ${({ order }) => order};

	place-items: ${({ placeItems }) => placeItems};
	place-self: ${({ placeSelf }) => placeSelf};
	place-content: ${({ placeContent }) => placeContent};

	row-gap: ${({ rowGap }) => (typeof rowGap === 'number' ? `${rowGap * gridSize()}px` : rowGap)};
	column-gap: ${({ columnGap }) =>
		typeof columnGap === 'number' ? `${columnGap * gridSize()}px` : columnGap};
`;
