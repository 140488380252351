import React from 'react';

import { Subscriber } from '@atlassian/conversation-assistant-pubsub';

import { useChatContextStoreActions } from './store';

export const ChatContextSubscriber = () => {
	const { setEditorContext, setBrowserContext } = useChatContextStoreActions();

	return (
		<Subscriber
			topic="ai-mate"
			onEvent={async (payload) => {
				// TODO https://product-fabric.atlassian.net/browse/AIM-1989 implement experience tracker
				try {
					switch (payload.type) {
						case 'editor-context-payload':
							setEditorContext(payload.data);
							break;
						case 'browser-context-payload':
							setBrowserContext(payload.data);
							break;
						default:
					}
				} catch (error) {}
			}}
		/>
	);
};
