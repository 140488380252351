import type { CacheConfig, RequestParameters, Variables } from 'relay-runtime';

const aggPathname = `/gateway/api/graphql${
	typeof process.env.AGG_BRANCH_SLUG === 'string' && process.env.AGG_BRANCH_SLUG !== ''
		? `/${process.env.AGG_BRANCH_SLUG}/graphql`
		: ''
}`;

export const getAggEndpoint = (
	request: RequestParameters,
	cacheConfig: CacheConfig = {},
): string => {
	let endpoint = aggPathname;
	if (cacheConfig.metadata?.META_SLOW_ENDPOINT) {
		endpoint = `${endpoint}/slow`;
	}
	if (request.id != null) {
		endpoint = `${endpoint}/pq/${request.id}`;
	}

	return `${endpoint}?operation=${request.name}`;
};

export const getRequestBody = (request: RequestParameters, variables: Variables) => {
	const requestBody: {
		operationName: string;
		variables: Variables;
		query?: string | null | undefined;
	} = {
		operationName: request.name,
		variables,
	};

	return JSON.stringify(requestBody);
};
