import { v4 as createUUID } from 'uuid';

import coinflip from '@atlassian/ufo-coinflip';
import { getDoNotAbortActivePressInteraction, getInteractionRate } from '@atlassian/ufo-config';
import { DefaultInteractionID } from '@atlassian/ufo-interaction-id-context';
import {
	abortAll,
	addNewInteraction,
	getActiveInteraction,
} from '@atlassian/ufo-interaction-metrics';
import UFORouteName from '@atlassian/ufo-route-name-context';

export default function traceUFOPress(name: string, timestamp?: number): void {
	const rate = getInteractionRate(name, 'press');
	const pressInteractionsList = getDoNotAbortActivePressInteraction();
	if (pressInteractionsList?.includes(name)) {
		const interaction = getActiveInteraction();
		if (interaction?.ufoName !== 'unknown' && interaction?.type === 'press') {
			return;
		}
	}
	if (coinflip(rate)) {
		abortAll('new_interaction', name);
		const startTimestamp = timestamp ?? performance.now();
		const newId = createUUID();
		DefaultInteractionID.current = newId;
		addNewInteraction(newId, name, 'press', startTimestamp, rate, [], UFORouteName.current);
	}
}
