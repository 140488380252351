import React, { type PropsWithChildren, useEffect, useState } from 'react';

import { IntlProvider, useIntl } from 'react-intl-next';

export const supportedLocales = [
	'cs-CZ',
	'da-DK',
	'de-DE',
	'es-ES',
	'et-EE',
	'fi-FI',
	'fr-FR',
	'hu-HU',
	'is-IS',
	'it-IT',
	'ja-JP',
	'ko-KR',
	'nb-NB',
	'nl-NL',
	'pl-PL',
	'pt-BR',
	'pt-PT',
	'ro-RO',
	'ru-RU',
	'sk-SK',
	'sv-SE',
	'tr-TR',
	'th-TH',
	'uk-UK',
	'vi-VI',
	'zh-TW',
	'zh-HK',
	'zh-ZH',
	'zh-CN',
];

export const getCodesFromLocale = (locale: string) => {
	const match = /([a-z]*)[_-]?([A-Z]*)/i.exec(locale);
	if (!match) {
		throw Error('Unable to get language and country from invalid Locale');
	}
	const [, language, country] = match;

	return {
		language: language.toLowerCase(),
		// when country code is missed, let it empty and we will use language/locale to find language file
		country: country ? country.toUpperCase() : '',
	};
};

type MessageFile = {
	default: { [key: string]: string };
};

// A function is used to lazy-load messages by a locale.
// This function must be implemented near consumer package because consumer packages know relative paths to message file.
type FetchLanguageFile = (locale: string) => Promise<MessageFile>;

export function useFetchMessagesByLocale(locale: string, fetchLanguageFile: FetchLanguageFile) {
	const [messages, setMessages] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		async function startToFetchMessagesByLocale() {
			const { language, country } = getCodesFromLocale(locale);

			// don't load English messages
			if (language === 'en') {
				return;
			}

			try {
				setIsLoading(true);
				const finalLocale = country ? `${language}-${country}` : language;
				const module = await fetchLanguageFile(finalLocale);
				const newMessages = module.default || module;
				setMessages(newMessages);
			} catch (e) {
				// fail to download message and do nothing because UI will use default language (English) to render
			} finally {
				setIsLoading(false);
			}
		}

		void startToFetchMessagesByLocale();
	}, [locale, fetchLanguageFile]);

	return { messages, isLoading };
}

/**
 * This component is a wrapper of `IntlProvider` from `react-intl`.
 * It allows to lazy load messages basing on current locale from consumers/products.
 */
export const LazyIntlProvider = (
	props: PropsWithChildren<{
		fetchLanguageFile: FetchLanguageFile;
	}>,
) => {
	const { children, fetchLanguageFile, ...rest } = props;

	const { locale } = useIntl();
	const { messages } = useFetchMessagesByLocale(locale, fetchLanguageFile);

	return (
		<IntlProvider {...rest} messages={messages} key={locale} locale={locale} defaultLocale="en-US">
			{children}
		</IntlProvider>
	);
};
