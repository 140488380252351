import React from 'react';

import Icon, { type CustomGlyphProps, type IconProps } from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';

const CustomGlyph = (props: CustomGlyphProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.8859 4.24256C12.3462 3.92351 11.6816 3.91921 11.1385 4.22964C11.1307 4.23541 11.1226 4.24083 11.1141 4.24585L11.114 4.2459L11.1129 4.24659C10.7631 4.45372 10.4987 4.77394 10.3536 5.14702C10.2734 5.35552 10.2305 5.58075 10.2305 5.81255V8.20867L13.1599 9.94087L13.1601 9.94098C13.8796 10.3649 14.3207 11.1485 14.3207 11.9983L14.3207 18.1841C14.3207 18.4616 14.2732 18.7325 14.1841 18.9861L18.1141 16.6613L18.1145 16.661C18.6633 16.3377 19 15.7403 19 15.0912V8.90544C19 8.25896 18.6614 7.6589 18.1142 7.33545L18.1141 7.33541L12.8859 4.24256Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.7695 18.1875C13.7695 18.4196 13.7265 18.645 13.6461 18.8537C13.5008 19.2269 13.2361 19.5472 12.8859 19.7542L12.8859 19.7542C12.8774 19.7592 12.8693 19.7646 12.8615 19.7704C12.3184 20.0808 11.6538 20.0765 11.1141 19.7575L11.114 19.7575L5.88586 16.6646L5.88579 16.6646C5.33861 16.3411 5 15.7411 5 15.0946L5 8.90882C5 8.25978 5.33663 7.66233 5.88543 7.33904L9.81585 5.01392C9.72678 5.26758 9.67926 5.53842 9.67926 5.81593V12.0017C9.67926 12.8514 10.1203 13.635 10.8397 14.059L10.8401 14.0592L13.7695 15.7914V18.1875Z"
			fill="currentColor"
		/>
	</svg>
);

export const RovoLogo = (props: Omit<IconProps, 'glyph'>) => (
	<Icon glyph={CustomGlyph} primaryColor={token('color.icon.brand')} {...props} />
);
