import { useAnalytics } from '@atlassian/conversation-assistant-instrumentation';
import { type Payload, useSubscribeAll } from '@atlassian/conversation-assistant-pubsub';

type Props = {
	onTriggerOpeningChat: () => void;
};

// TODO: Deprecated; teams integrating this event should opt-out of chat open using `openChat` on the core event payload
// Once all teams have opted out the ignoredEvents logic can be removed
const ignoredEvents = new Set<Payload['type']>([
	'editor-context-payload',
	'browser-context-payload',
]);

/**
 * This component listens to all pubsub events emitted
 * for specific events, it will call `onEventTriggersChatOpen`
 *
 * Example use case: if Rovo chat is closed in Confluence sidebar
 * and there is a reading-aids follow up triggering 'chat-new' pubsub event
 * We want to open the chat, and create a new conversation following that
 *
 * This component will be responsible to handle opening the chat, by calling `onEventTriggersChatOpen`
 * Each product will pass their own callback on how to open chat
 */
export const ChatOpenerSubscriber = ({ onTriggerOpeningChat }: Props) => {
	const { sendAnalyticsEvent } = useAnalytics();

	useSubscribeAll((payload) => {
		const { type, source, openChat = true } = payload;
		const preventOpen = !openChat || ignoredEvents.has(type);

		sendAnalyticsEvent({
			action: 'eventReceived',
			actionSubject: 'aiMatePubSubEvent',
			source,
			attributes: {
				publisherSource: source,
				payloadType: type,
				eventTriggersChatOpen: !preventOpen,
			},
		});

		if (preventOpen) {
			return;
		}

		onTriggerOpeningChat();
	});

	return null;
};
