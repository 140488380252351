import { FabricChannel } from '@atlaskit/analytics-listeners';

export const ANALYTICS_CHANNEL = FabricChannel.aiMate;

// What triggered the interaction / message
export enum InteractionSource {
	CHAT_INPUT = 'chatInput',
	FOLLOW_UPS = 'followUps',
	MOVED_OVER = 'movedOver',
	EMPTY_STATE_SUGGESTIONS = 'emptyStateSuggestions',
	VIEW_AGENT_CONVERSATION_STARTERS = 'viewAgentConversationStarters',
	URL_PARAM = 'urlParam',
}

// Which context a follow up was triggered from
export enum FollowUpSource {
	NONE = 'none',
	SAIN = 'sain',
	READING_AIDS = 'reading-aids',
	PAGE_SUMMARY = 'page-summary',
	AI_MATE = 'ai-mate',
	EXTENSION = 'extension',
}
