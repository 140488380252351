import { createActionsHook, createHook, createStore } from 'react-sweet-state';

import type {
	BrowserContextPayloadData,
	EditorContextPayloadData,
} from '@atlassian/conversation-assistant-pubsub';

type Store = {
	editorContext: EditorContextPayloadData;
	browserContext?: BrowserContextPayloadData;
};

const store = createStore({
	initialState: {
		editorContext: undefined,
		browserContext: undefined,
	} as Store,
	actions: {
		getEditorContext:
			() =>
			({ getState }) =>
				getState().editorContext,
		setEditorContext:
			(editorContext: EditorContextPayloadData) =>
			({ setState }) => {
				setState({
					editorContext,
				});
			},
		getBrowserContext:
			() =>
			({ getState }) =>
				getState().browserContext,
		setBrowserContext:
			(browserContext: BrowserContextPayloadData) =>
			({ setState }) => {
				setState({
					browserContext,
				});
			},
	},
});

export const useChatContextStoreActions = createActionsHook(store);
export const useChatContextEditorContext = createHook(store, {
	selector: (state) => state.editorContext,
});
