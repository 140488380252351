import {
	TEAMS_CLIENT_EXPERIENCES,
	type TeamsClientExperienceKeys,
} from '../common/utils/ufo/constants';
import { type MembershipState, type TeamMembership } from '../types';

import { aggClient } from './agg-client';
import { type AGGPageInfoVariables, type ResultWithPageInfo } from './agg-client/types';
import { atlasClient } from './atlas-client';
import { directoryClient } from './directory-client';
import invitationsClient from './invitations-client';
import { defaultLegionClient } from './legion-client';
import mutabilityClient from './mutability-client';
import permsClient from './perms-client';
import publicApiClient from './public-api-client';
import { type ClientContextProps } from './types';
import userPreferencesClient from './user-preferences-client';

/**
 * This solves error TS1055:
 * Type 'ReturnType' is not a valid async function return type in ES5/ES3 because it does not refer to a Promise-compatible constructor value
 */
type AwaitedReturn<T extends (...args: any) => any> = Awaited<ReturnType<T>>;

/**
 * The Teams Client is an used for fetching data related to Atlas teams.
 * It is an abstraction over the various clients that are used to fetch data related to teams.
 * Find out more about the APIs here - [https://developer.atlassian.com/platform/teams-internal/](https://developer.atlassian.com/platform/teams-internal/)
 */
export class TeamsClient {
	private readonly _aggClient = aggClient;
	private readonly _atlasClient = atlasClient;
	private readonly _directoryClient = directoryClient;
	private readonly _legionClient = defaultLegionClient;
	private readonly _mutabilityClient = mutabilityClient;
	private readonly _permsClient = permsClient;
	private readonly _publicApiClient = publicApiClient;
	private readonly _invitationsClient = invitationsClient;
	private readonly _userPreferencesClient = userPreferencesClient;

	constructor(
		/**
		 * @param {ClientContextProps} context - Context including CloudId & OrgId to be used for all requests
		 */
		context?: ClientContextProps,
	) {
		if (context) {
			this.setContext(context);
		}
	}

	private async measurePerformance<Response>(
		name: TeamsClientExperienceKeys,
		fn: () => Promise<Response>,
	): Promise<Response> {
		const exp = TEAMS_CLIENT_EXPERIENCES.get(name);
		if (!exp) {
			return fn();
		}
		exp.start();
		try {
			const result = await fn();
			exp.success();
			return result;
		} catch (e) {
			exp.failWithError(e);
			throw e;
		}
	}

	/**
	 * Sets the base URL to be used in the client requests
	 * @param {string} baseUrl - The new base URL
	 */
	setBaseUrl(baseUrl: string) {
		this._aggClient.setBaseUrl(baseUrl);
		this._atlasClient.setBaseUrl(baseUrl);
		this._directoryClient.setBaseUrl(baseUrl);
	}

	/**
	 * Sets context including CloudId & OrgId to be used for all requests
	 */
	setContext(context: ClientContextProps) {
		this._aggClient.setContext(context);
		this._atlasClient.setContext(context);
		this._directoryClient.setContext(context);
		this._legionClient.setContext(context);
		this._permsClient.setContext(context);
		this._mutabilityClient.setContext(context);
		this._publicApiClient.setContext(context);
		this._invitationsClient.setContext(context);
	}

	/**
	 * @private
	 * @deprecated use setBaseUrl instead
	 */
	setDirectoryBaseUrl(baseUrl: string) {
		// eslint-disable-next-line no-console
		console.warn('setDirectoryBaseUrl is deprecated, use setBaseUrl instead');
		return this._directoryClient.setBaseUrl(baseUrl);
	}

	/**
	 * Fetches Browse user settings
	 */
	async querySettings(
		...args: Parameters<typeof directoryClient.querySettings>
	): Promise<AwaitedReturn<typeof directoryClient.querySettings>> {
		return this.measurePerformance('querySettings', () =>
			this._directoryClient.querySettings(...args),
		);
	}

	/**
	 * Fetches feature flags from Directory
	 * @deprecated As part of decommisioning pf-directoy
	 */
	async queryFeatureFlags(
		...args: Parameters<typeof directoryClient.queryFeatureFlags>
	): Promise<AwaitedReturn<typeof directoryClient.queryFeatureFlags>> {
		return this.measurePerformance('queryFeatureFlags', () =>
			this._directoryClient.queryFeatureFlags(...args),
		);
	}

	/**
	 * Fetches team health monitor details for a team from Atlas
	 */
	async queryTeamHealthMonitors(
		...args: Parameters<typeof atlasClient.queryTeamHealthMonitors>
	): Promise<AwaitedReturn<typeof atlasClient.queryTeamHealthMonitors>> {
		return this.measurePerformance('queryTeamHealthMonitors', () =>
			this._atlasClient.queryTeamHealthMonitors(...args),
		);
	}

	/**
	 * Get user information of members of a team, paginated
	 * @param {string} teamId
	 * @returns {Promise}
	 */
	async queryTeamMemberships(
		teamId: string,
		membershipState: MembershipState[] = ['FULL_MEMBER'],
		pageInfo: AGGPageInfoVariables = {
			first: 100,
		},
	): Promise<ResultWithPageInfo<TeamMembership>> {
		return this._aggClient.queryTeamMemberships(teamId, membershipState, pageInfo);
	}

	/**
	 * Get user information given an user id
	 * @param {string} userId
	 * @returns {Promise}
	 */
	async queryAGGUser(userId: string): Promise<AwaitedReturn<typeof aggClient.queryAGGUser>> {
		return this._aggClient.queryAGGUser(userId);
	}

	/**
	 * Get user information of a member of a team
	 * @param {string} userId
	 * @returns {Promise<CloudUser>}
	 */
	queryUser(
		...args: Parameters<typeof directoryClient.queryUser>
	): Promise<AwaitedReturn<typeof directoryClient.queryUser>> {
		return this.measurePerformance('queryUser', () => this._directoryClient.queryUser(...args));
	}

	setLegionRootUrl(url: string) {
		return this._legionClient.setRootUrl(url);
	}

	/**
	 *
	 * @param teamId
	 * @returns {Promise<TeamWithImageUrls>}
	 */
	async getTeamById(
		teamId: string,
	): Promise<AwaitedReturn<typeof defaultLegionClient.getTeamById>> {
		return this.measurePerformance('getTeamById', () => this._legionClient.getTeamById(teamId));
	}

	/**
	 * Search teams based on query
	 */
	async getAllTeams(
		...args: Parameters<typeof defaultLegionClient.getAllTeams>
	): Promise<AwaitedReturn<typeof defaultLegionClient.getAllTeams>> {
		return this.measurePerformance('getAllTeams', () => this._legionClient.getAllTeams(...args));
	}

	/**
	 * Update team details
	 */
	async updateTeamById(
		...args: Parameters<typeof defaultLegionClient.updateTeamById>
	): Promise<AwaitedReturn<typeof defaultLegionClient.updateTeamById>> {
		return this.measurePerformance('updateTeamById', () =>
			this._legionClient.updateTeamById(...args),
		);
	}

	/**
	 * @private
	 * @deprecated use addUsersToTeam instead
	 */
	async inviteUsersToTeam(
		...args: Parameters<typeof defaultLegionClient.inviteUsersToTeam>
	): Promise<AwaitedReturn<typeof defaultLegionClient.inviteUsersToTeam>> {
		return this.measurePerformance('inviteUsersToTeam', () =>
			this._legionClient.inviteUsersToTeam(...args),
		);
	}

	/**
	 * Get the current user's team membership
	 */
	async getMyTeamMembership(
		...args: Parameters<typeof defaultLegionClient.getMyTeamMembership>
	): Promise<AwaitedReturn<typeof defaultLegionClient.getMyTeamMembership>> {
		return this.measurePerformance('getMyTeamMembership', () =>
			this._legionClient.getMyTeamMembership(...args),
		);
	}

	/**
	 * Get a teams "Links"
	 */
	async getTeamLinksByTeamId(
		teamId: string,
	): Promise<AwaitedReturn<typeof defaultLegionClient.getTeamLinksByTeamId>> {
		return this.measurePerformance('getTeamLinksByTeamId', () =>
			this._legionClient.getTeamLinksByTeamId(teamId),
		);
	}

	/**
	 * Create a team link
	 */
	async createTeamLink(
		...args: Parameters<typeof defaultLegionClient.createTeamLink>
	): Promise<AwaitedReturn<typeof defaultLegionClient.createTeamLink>> {
		return this.measurePerformance('createTeamLink', () =>
			this._legionClient.createTeamLink(...args),
		);
	}

	/**
	 * Update a team link
	 */
	async updateTeamLink(
		...args: Parameters<typeof defaultLegionClient.updateTeamLink>
	): Promise<AwaitedReturn<typeof defaultLegionClient.updateTeamLink>> {
		return this.measurePerformance('updateTeamLink', () =>
			this._legionClient.updateTeamLink(...args),
		);
	}

	/**
	 * Delete a team link
	 */
	async deleteTeamLink(
		...args: Parameters<typeof defaultLegionClient.deleteTeamLink>
	): Promise<void> {
		return this.measurePerformance('deleteTeamLink', () =>
			this._legionClient.deleteTeamLink(...args),
		);
	}

	/**
	 * Reorder a team link
	 */
	async reorderTeamLink(
		...args: Parameters<typeof defaultLegionClient.reorderTeamLink>
	): Promise<AwaitedReturn<typeof defaultLegionClient.reorderTeamLink>> {
		return this.measurePerformance('reorderTeamLink', () =>
			this._legionClient.reorderTeamLink(...args),
		);
	}

	/**
	 * @deprecated Invitation status no longer exists
	 * Accept a team invitation
	 */
	async acceptTeamInvitation(
		...args: Parameters<typeof defaultLegionClient.acceptTeamInvitation>
	): Promise<AwaitedReturn<typeof defaultLegionClient.acceptTeamInvitation>> {
		return this.measurePerformance('acceptTeamInvitation', () =>
			this._legionClient.acceptTeamInvitation(...args),
		);
	}

	/**
	 * @deprecated Invitation status no longer exists
	 * Decline a team invitation
	 */
	async declineTeamInvitation(
		...args: Parameters<typeof defaultLegionClient.declineTeamInvitation>
	): Promise<void> {
		return this.measurePerformance('declineTeamInvitation', () =>
			this._legionClient.declineTeamInvitation(...args),
		);
	}

	/**
	 * Add current user to a team
	 */
	async selfJoinTeam(
		...args: Parameters<typeof defaultLegionClient.selfJoinTeam>
	): Promise<AwaitedReturn<typeof defaultLegionClient.selfJoinTeam>> {
		return this.measurePerformance('selfJoinTeam', () => this._legionClient.selfJoinTeam(...args));
	}

	/**
	 * Cancel a join request to a private team
	 */
	async cancelJoinRequest(teamId: string): Promise<void> {
		return this.measurePerformance('cancelJoinRequest', () =>
			this._legionClient.cancelJoinRequest(teamId),
		);
	}

	/**
	 * Accept a join request to a private team
	 */
	async acceptJoinRequest(
		...args: Parameters<typeof defaultLegionClient.acceptJoinRequest>
	): Promise<AwaitedReturn<typeof defaultLegionClient.acceptJoinRequest>> {
		return this.measurePerformance('acceptJoinRequest', () =>
			this._legionClient.acceptJoinRequest(...args),
		);
	}

	/**
	 * Decline a join request to a private team
	 */
	async declineJoinRequest(
		...args: Parameters<typeof defaultLegionClient.declineJoinRequest>
	): Promise<void> {
		return this.measurePerformance('declineJoinRequest', () =>
			this._legionClient.declineJoinRequest(...args),
		);
	}

	/**
	 * @deprecated use removeTeamMemberships instead
	 */
	async deleteTeamMembership(
		...args: Parameters<typeof defaultLegionClient.deleteTeamMembership>
	): Promise<AwaitedReturn<typeof defaultLegionClient.deleteTeamMembership>> {
		return this.measurePerformance('deleteTeamMembership', () =>
			this._legionClient.deleteTeamMembership(...args),
		);
	}

	/**
	 * @deprecated INVITED status has been deprecated
	 */
	async revokeTeamInvite(
		...args: Parameters<typeof defaultLegionClient.revokeTeamInvite>
	): Promise<AwaitedReturn<typeof defaultLegionClient.revokeTeamInvite>> {
		return this.measurePerformance('revokeTeamInvite', () =>
			this._legionClient.revokeTeamInvite(...args),
		);
	}

	/**
	 * Delete a team
	 */
	async deleteTeam(teamId: string): Promise<void> {
		return this.measurePerformance('deleteTeam', () => this._legionClient.deleteTeam(teamId));
	}

	/**
	 * @private
	 * @deprecated should be other means to solve this issue
	 */
	async isSingleFullMemberTeam(teamId: string): Promise<boolean> {
		return this._legionClient.isSingleFullMemberTeam(teamId);
	}

	/**
	 * Create a team
	 */
	async createTeam(
		...args: Parameters<typeof defaultLegionClient.createTeam>
	): Promise<AwaitedReturn<typeof defaultLegionClient.createTeam>> {
		return this.measurePerformance('createTeam', () => this._legionClient.createTeam(...args));
	}

	/**
	 *
	 * @param teamId
	 * @returns {Promise<SoftDeletedTeam>}
	 */
	async getSoftDeletdTeamById(
		teamId: string,
	): Promise<AwaitedReturn<typeof defaultLegionClient.getSoftDeletedTeamById>> {
		return this.measurePerformance('getSoftDeletedTeamById', () =>
			this._legionClient.getSoftDeletedTeamById(teamId),
		);
	}

	/**
	 * Shoud only be used within Teams (P&T) packages
	 * @param userId
	 * @returns {Promise<SoftDeletedTeam>}
	 */
	async getUserInSiteUserBase(
		userId: string,
	): Promise<AwaitedReturn<typeof defaultLegionClient.getUserInSiteUserBase>> {
		return this.measurePerformance('getUserInSiteUserBase', () =>
			this._legionClient.getUserInSiteUserBase(userId),
		);
	}

	trimTeamARI(teamId = '') {
		return this._legionClient.trimTeamARI(teamId);
	}

	/**
	 * Get user profile with mutability constraints
	 */
	async getProfileWithMutability(
		...args: Parameters<typeof mutabilityClient.getProfileWithMutability>
	): Promise<AwaitedReturn<typeof mutabilityClient.getProfileWithMutability>> {
		return this.measurePerformance('getProfileWithMutability', () =>
			this._mutabilityClient.getProfileWithMutability(...args),
		);
	}

	/**
	 * Is the current user a site admin
	 */
	getIsSiteAdmin(
		...args: Parameters<typeof permsClient.getIsSiteAdmin>
	): Promise<AwaitedReturn<typeof permsClient.getIsSiteAdmin>> {
		return this.measurePerformance('getIsSiteAdmin', () =>
			this._permsClient.getIsSiteAdmin(...args),
		);
	}

	/**
	 * Add users to a team
	 */
	addUsersToTeam(
		...args: Parameters<typeof publicApiClient.addUsersToTeam>
	): Promise<AwaitedReturn<typeof publicApiClient.addUsersToTeam>> {
		return this.measurePerformance('addUsersToTeam', () =>
			this._publicApiClient.addUsersToTeam(...args),
		);
	}

	/**
	 * Remove users from a team
	 */
	removeTeamMemberships(
		...args: Parameters<typeof publicApiClient.removeTeamMemberships>
	): Promise<AwaitedReturn<typeof publicApiClient.removeTeamMemberships>> {
		return this.measurePerformance('removeTeamMemberships', () =>
			this._publicApiClient.removeTeamMemberships(...args),
		);
	}

	/**
	 * Restore a deleted team
	 */
	restoreTeam(
		...args: Parameters<typeof publicApiClient.restoreTeam>
	): Promise<AwaitedReturn<typeof publicApiClient.restoreTeam>> {
		return this.measurePerformance('restoreTeam', () => this._publicApiClient.restoreTeam(...args));
	}

	getRecommendedProducts(
		...args: Parameters<typeof invitationsClient.getProductRecommendations>
	): Promise<AwaitedReturn<typeof invitationsClient.getProductRecommendations>> {
		return this.measurePerformance('getRecommendedProducts', () =>
			this._invitationsClient.getProductRecommendations(...args),
		);
	}

	joinOrRequestDefaultAccessToProductsBulk(
		...args: Parameters<typeof invitationsClient.joinOrRequestDefaultAccessToProductsBulk>
	): Promise<AwaitedReturn<typeof invitationsClient.joinOrRequestDefaultAccessToProductsBulk>> {
		return this.measurePerformance('joinOrRequestDefaultAccessToProductsBulk', () =>
			this._invitationsClient.joinOrRequestDefaultAccessToProductsBulk(...args),
		);
	}

	/**
	 * Get token to upload media for a team
	 */
	async getWriteTeamMediaToken(): Promise<
		AwaitedReturn<typeof userPreferencesClient.getReadMediaToken>
	> {
		return this.measurePerformance('getWriteTeamMediaToken', () =>
			this._legionClient.getWriteMediaToken(),
		);
	}

	/**
	 * Get token to read media for a user
	 */
	async getReadMediaToken(
		...args: Parameters<typeof userPreferencesClient.getReadMediaToken>
	): Promise<AwaitedReturn<typeof userPreferencesClient.getReadMediaToken>> {
		return this.measurePerformance('getReadMediaToken', () =>
			this._userPreferencesClient.getReadMediaToken(...args),
		);
	}

	/**
	 * Get token to upload media for a user
	 */
	async getWriteUserMediaToken(): Promise<
		AwaitedReturn<typeof userPreferencesClient.getReadMediaToken>
	> {
		return this.measurePerformance('getWriteUserMediaToken', () =>
			this._userPreferencesClient.getWriteMediaToken(),
		);
	}

	/**
	 * Update header image for a user
	 */
	async updateUserHeaderImage(
		headerImageId: string | null,
	): Promise<AwaitedReturn<typeof userPreferencesClient.updateUserHeaderImage>> {
		return this.measurePerformance('updateUserHeaderImage', () =>
			this._userPreferencesClient.updateUserHeaderImage(headerImageId),
		);
	}
}

export const teamsClient = new TeamsClient();
