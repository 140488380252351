import { type SourceAndAttributes } from './types';

const isSourceAndAttributes = (x: unknown): x is SourceAndAttributes => {
	if (typeof x !== 'object' || x === null) {
		return false;
	}

	if (
		!('source' in x) ||
		!('attributes' in x) ||
		typeof x.attributes !== 'object' ||
		x.attributes === null
	) {
		return false;
	}

	return true;
};

/**
 * Extracts the attributes from the relevant AIMate contexts.
 * Contexts is an array of (any) object - to determine which objects we want to extract the attributes from,
 * we add a source of 'AIMate' to the object.
 */
export const getAttributesFromContexts = (context: unknown): Record<string, any> => {
	if (!context || !Array.isArray(context)) {
		return {};
	}

	let aiMateOnly = context.filter((x) => isSourceAndAttributes(x) && x.source === 'AIMate');

	return aiMateOnly.reduce((acc, x) => {
		return {
			...acc,
			...(x.attributes ?? {}),
		};
	}, {});
};
