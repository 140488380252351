import {
  JiraProjectAriResourceOwner,
  JiraProjectAriResourceType
} from "./chunk-CQAE6WHE.mjs";
import {
  RegisteredAri
} from "./chunk-CLQNXEWF.mjs";
import {
  AriParser
} from "./chunk-HNZGDVUS.mjs";

// src/jira/project/manifest.ts
var jiraProjectAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9-]+$"),
  resourceOwner: JiraProjectAriResourceOwner,
  resourceType: JiraProjectAriResourceType,
  resourceIdSlug: "{projectId}",
  resourceIdSegmentFormats: {
    projectId: /[0-9]+/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/project/index.ts
var JiraProjectAri = class _JiraProjectAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._projectId = opts.resourceIdSegmentValues.projectId;
  }
  get siteId() {
    return this._siteId;
  }
  get projectId() {
    return this._projectId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraProjectAriStaticOpts.qualifier,
      platformQualifier: jiraProjectAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraProjectAriStaticOpts.resourceOwner,
      resourceType: jiraProjectAriStaticOpts.resourceType,
      resourceId: `${opts.projectId}`,
      resourceIdSegmentValues: {
        projectId: opts.projectId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraProjectAriStaticOpts);
    return new _JiraProjectAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraProjectAriStaticOpts);
    return new _JiraProjectAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      projectId: this.projectId
    };
  }
};

export {
  JiraProjectAri
};
