export default function RelayDataID(
	fieldValue: {
		[key: string]: unknown;
	},
	typeName: string,
) {
	const { id } = fieldValue;
	if (id == null) {
		return undefined;
	}
	return `${String(id)}:::::${typeName}`;
}
