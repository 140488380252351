import React, {
	createContext,
	type ReactNode,
	useCallback,
	useContext,
	useMemo,
	useRef,
} from 'react';

import { AnalyticsContext } from '@atlaskit/analytics-next';

import type { AIMateAttributeType, AnalyticsPropertiesContextProps } from './types';

const AnalyticsPropertiesContext = createContext<AnalyticsPropertiesContextProps>({
	propertiesRef: {
		current: {
			conversationChannelId: '',
			experienceId: '',
			product: '',
			agentId: null,
			fullScreen: false,
			agentCreatorType: '',
			agentVisibility: '',
			singleInstrumentationID: '',
		},
	},
	setAnalyticsPropertiesWithoutRerender: () => {},
});

/**
 * This context contains ref to the properties that will be added to `useAnalytics` when firing an event
 * Using `ref` so this context does not rerender when any of the analytics properties change
 * Because the updated attributes are only needed when the analytics is fired
 */
export const AnalyticsPropertiesContextProvider = ({ children }: { children: ReactNode }) => {
	const propertiesRef = useRef<AIMateAttributeType>({
		conversationChannelId: '',
		experienceId: '',
		product: '',
		agentId: null,
		fullScreen: false,
		agentCreatorType: '',
		agentVisibility: '',
		singleInstrumentationID: '',
	});

	const setAnalyticsPropertiesWithoutRerender = useCallback(
		(properties: Partial<AIMateAttributeType>) => {
			propertiesRef.current = { ...propertiesRef.current, ...properties };
		},
		[],
	);

	const value = useMemo(
		() => ({
			propertiesRef,
			setAnalyticsPropertiesWithoutRerender,
		}),
		[setAnalyticsPropertiesWithoutRerender],
	);

	return (
		<AnalyticsPropertiesContext.Provider value={value}>
			{children}
		</AnalyticsPropertiesContext.Provider>
	);
};

/**
 * Adding extra wrapper to AnalyticsPropertiesContextProvider
 * for `source` attribute
 * using AnalyticsContext
 */
export const AnalyticsContextWithSourceAndProperties = ({
	source,
	children,
}: {
	source: string;
	children: ReactNode;
}) => {
	const data = useMemo(() => ({ source }), [source]);

	return (
		<AnalyticsContext data={data}>
			<AnalyticsPropertiesContextProvider>{children}</AnalyticsPropertiesContextProvider>
		</AnalyticsContext>
	);
};

export const useAnalyticsPropertiesContext = () => {
	return useContext(AnalyticsPropertiesContext);
};
