/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import Button from '@atlaskit/button';
import ChevronIcon from '@atlaskit/icon/glyph/chevron-down';
import { token } from '@atlaskit/tokens';

import { RenotifySpotlightTriggerChevron } from './styled';

interface Props {
	testId: string;
	triggerProps: any;
	children: React.ReactNode;
}

const buttonStyles = css({
	padding: `${token('space.0', '0px')} ${token('space.050', '4px')}`,
});

function RenotifyMenuButton(props: Props) {
	const { testId, triggerProps, children } = props;

	// The cloned spotlight button requires the className prop to be set
	// for the padding style to be applied properly
	return (
		<Button
			data-testid={testId}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="renotify-menu-button"
			css={buttonStyles}
			iconAfter={
				<RenotifySpotlightTriggerChevron>
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
					<ChevronIcon label="dropdown-chevron" />
				</RenotifySpotlightTriggerChevron>
			}
			{...triggerProps}
		>
			{children}
		</Button>
	);
}

export default RenotifyMenuButton;
