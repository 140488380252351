import * as loaders from './loaders';
import type { MessageFile } from './types';

export function fetchLanguageFile(locale: string): Promise<MessageFile> {
	switch (locale) {
		case 'cs-CZ':
		case 'cs':
			return loaders.cs();
		case 'da-DK':
		case 'da':
			return loaders.da();
		case 'de-DE':
		case 'de':
			return loaders.de();
		case 'es-ES':
		case 'es':
			return loaders.es();
		case 'et-EE':
		case 'et':
			return loaders.et();
		case 'fi-FI':
		case 'fi':
			return loaders.fi();
		case 'fr-FR':
		case 'fr':
			return loaders.fr();
		case 'hu-HU':
		case 'hu':
			return loaders.hu();
		case 'is-IS':
		case 'is':
			return loaders.is();
		case 'it-IT':
		case 'it':
			return loaders.it();
		case 'ja-JP':
		case 'ja':
			return loaders.ja();
		case 'ko-KR':
		case 'ko':
			return loaders.ko();
		case 'nb-NB':
		case 'nb':
			return loaders.nb();
		case 'nl-NL':
		case 'nl':
			return loaders.nl();
		case 'pl-PL':
		case 'pl':
			return loaders.pl();
		case 'pt-PT':
		case 'pt_PT':
		case 'pt':
			return loaders.pt();
		case 'pt-BR':
		case 'pt_BR':
			return loaders.ptbr();
		case 'ro-RO':
		case 'ro':
			return loaders.ro();
		case 'ru-RU':
		case 'ru':
			return loaders.ru();
		case 'sk-SK':
		case 'sk':
			return loaders.sk();
		case 'sv-SE':
		case 'sv':
			return loaders.sv();
		case 'tr-TR':
		case 'tr':
			return loaders.tr();
		case 'th-TH':
		case 'th':
			return loaders.th();
		case 'uk-UK':
		case 'uk':
			return loaders.uk();
		case 'vi-VI':
		case 'vi':
			return loaders.vi();
		case 'zh-TW':
		case 'zh-HK':
			return loaders.zhtw();
		case 'zh-ZH':
		case 'zh-CN':
		case 'zh':
			return loaders.zh();
	}

	return loaders.fallback();
}
