import { teamsClient } from '@atlassian/teams-client/client';
import type { Product } from '@atlassian/teams-common/types';
import { constructProductOriginParams } from '@atlassian/teams-common/urls';

import { type Team } from '../types';
import {
	type Membership,
	type RawTeamData,
	TeamDiscoverability,
	TeamMembershipSettings,
	TeamPermission,
	TeamRestriction,
	TeamState,
} from '../types/team';
import { type InvitedUser } from '../types/user';
import { ErrorWithContext } from '../utils/error';
import { fetchJson, postJson } from '../utils/fetch';

const LIMIT_RESULTS = 5;
export const STARGATE = '/gateway/api';
export const STARGATE_ENDPOINT_TEAMS = `${STARGATE}/v3/teams`;
export const STARGATE_ENDPOINT_TEAMS_V4 = `${STARGATE}/v4/teams`;
export const STARGATE_ENDPOINT_TEAMS_OF = `${STARGATE_ENDPOINT_TEAMS}/of-user`;
export const STARGATE_ENDPOINT_TEAMS_SEACH = `${STARGATE_ENDPOINT_TEAMS_V4}/search`;

export const buildTeamsOfUrl = (
	userId: string,
	cloudId: string,
	product: string,
	tenantUrl: string = '',
	orgId?: string,
) => {
	const params = [
		`limit=${LIMIT_RESULTS}`,
		'cursor=',
		`organizationId=${orgId ?? ''}`,
		`origin.cloudId=${cloudId}`,
		`origin.product=${product.toUpperCase()}`,
	];

	return `${tenantUrl}${STARGATE_ENDPOINT_TEAMS_OF}/${userId}?&${params.join('&')}`;
};

export const buildTeamsSearchUrl = (cloudId: string, product: string, tenantUrl: string = '') =>
	`${tenantUrl}${STARGATE_ENDPOINT_TEAMS_SEACH}${constructProductOriginParams({
		product,
		cloudId,
	})}`;

export const fetchMyTeams = (
	userId: string,
	cloudId: string,
	product: string,
	tenantUrl: string = '',
	orgId?: string,
): Promise<RawTeamData> =>
	orgId
		? fetchJson(buildTeamsOfUrl(userId, cloudId, product, tenantUrl, orgId))
		: // If `orgId` is not present, just make this request hang forever as though loading.
			new Promise<RawTeamData>(() => {});

export const searchTeamsOfUser = (
	userId: string,
	cloudId: string,
	product: string,
	tenantUrl: string = '',
	orgId?: string,
): Promise<RawTeamData> => {
	if (!userId || !cloudId || !orgId) {
		throw new ErrorWithContext('Missing parameters', {
			userId,
			cloudId,
			orgId,
			product,
			tenantUrl,
		});
	}

	const url = buildTeamsSearchUrl(cloudId, product, tenantUrl);
	const postData = {
		organizationId: orgId,
		siteId: cloudId,
		limit: LIMIT_RESULTS,
		membership: {
			memberAccountIds: [userId],
		},
	};

	return postJson(url, postData);
};

export interface MetaDataTeamCreation {
	product: Product;
	cloudId: string;
	tenantUrl?: string;
	orgId?: string;
	members?: string[];
}

export const postCreateTeam = async (
	teamName: string,
	membershipSettings: TeamMembershipSettings,
	metaData: MetaDataTeamCreation,
): Promise<Team> => {
	teamsClient.setContext({
		cloudId: metaData.cloudId,
		orgId: metaData.orgId,
	});

	teamsClient.setBaseUrl(`${metaData.tenantUrl ?? ''}${STARGATE}`);

	const result = await teamsClient.createTeam(
		teamName,
		{
			product: metaData.product,
		},
		[...new Set(metaData.members)],
		membershipSettings === TeamMembershipSettings.OPEN ? 'OPEN' : 'MEMBER_INVITE',
	);

	return {
		id: result.id,
		displayName: result.displayName,
		description: result.description,
		state: result.state === 'ACTIVE' ? TeamState.ACTIVE : TeamState.PURGED,
		membershipSettings:
			result.membershipSettings === 'OPEN'
				? TeamMembershipSettings.OPEN
				: TeamMembershipSettings.INVITE_ONLY,
		memberCount: result.memberIds?.length ?? 0,
		teamAri: `ari:cloud:teams::team/${result.id}`,
		discoverable: TeamDiscoverability.DISCOVERABLE,
		organizationId: result.organizationId,
		restriction: TeamRestriction.NO_RESTRICTION,
		creatorId: result.creatorId,
		permission:
			result.permission === 'FULL_WRITE'
				? TeamPermission.FULL_WRITE
				: result.permission === 'FULL_READ'
					? TeamPermission.FULL_READ
					: TeamPermission.NONE,
	};
};

export const buildInviteTeamMembersUrl = (
	teamId: string,
	{ product, cloudId, tenantUrl = '' }: MetaDataTeamCreation,
) => {
	return `${tenantUrl}${STARGATE_ENDPOINT_TEAMS}/ui/${teamId}/membership/invite${constructProductOriginParams(
		{
			product,
			cloudId,
		},
	)}`;
};

export const postInviteTeamMembers = async (
	teamId: string,
	users: InvitedUser[],
	metaData: MetaDataTeamCreation,
): Promise<Membership[] | undefined> => {
	if (!users || users.length === 0) {
		return Promise.resolve(undefined);
	}

	const emails: string[] = [];
	const ids: string[] = [];

	users.forEach((user) => {
		if (user.id) {
			ids.push(user.id);
		} else if (user.email) {
			emails.push(user.email);
		}
	});

	const url = buildInviteTeamMembersUrl(teamId, metaData);

	const postData = {
		emailAddresses: emails,
		atlassianAccounts: ids,
	};

	return postJson(url, postData);
};
