import React from 'react';

import { useIntl } from 'react-intl-next';

import { PrimaryButton } from '@atlaskit/atlassian-navigation';
import { type ButtonProps } from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

import { RovoLogo } from '../../common/ui/rovo-logo';

import { messages } from './messages';

type Props = {
	testId?: string;
	isActive: boolean;
	onClick: ButtonProps['onClick'];
	tooltipContent?: React.ReactNode;
};

const defaultButtonStyles = {
	paddingInline: '8px',
};

const inactiveButtonStyles = {
	background: token('color.background.neutral'),
};

export const ConversationAssistantButton = ({
	testId,
	isActive,
	onClick,
	tooltipContent,
}: Props) => {
	const { formatMessage } = useIntl();
	return (
		<PrimaryButton
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ ...defaultButtonStyles, ...(!isActive && inactiveButtonStyles) }}
			iconBefore={<RovoLogo size="medium" label="" primaryColor="currentColor" />}
			testId={testId}
			isSelected={isActive}
			onClick={onClick}
			tooltip={tooltipContent ?? formatMessage(messages.buttonLabel)}
		>
			{formatMessage(messages.buttonLabel)}
		</PrimaryButton>
	);
};
